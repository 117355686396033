import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import './content.css';
import Photo from '../images/photo.jpg';

const HomeContent = () => (
  <div>
    <div className="container container--start mx-auto px-4 flex flex-wrap text">
      <div className="p-4 pb-0">
        <div className="float-right">
          <div className="pl-6 pt-0 pr-0">
            <img src={Photo} className="photo" alt="Monika Martinková" />
          </div>
        </div>
        <p className="text-semi">
          <FormattedHTMLMessage id="home.intro1" />
        </p>
        <p className="text-semi">
          <FormattedHTMLMessage id="home.intro2" />
        </p>
        <div className="red-hr"></div>
      </div>
    </div>
    <div className="masonry">
      <div className="masonry__items cont-order-1 masonry__item--left p-4">
        <h2>
          <FormattedHTMLMessage id="offering.heading" />
        </h2>
        <p>
          <FormattedHTMLMessage id="offering.content" />
        </p>
        <p>
          <FormattedHTMLMessage id="offering.legal" />
        </p>
        <p>
          <FormattedHTMLMessage id="offering.tools" />
        </p>
      </div>

      <div className="masonry__item cont-order-4 masonry__item--left p-4">
        <h2>
          <FormattedHTMLMessage id="about.title" />
        </h2>
        <p>
          <FormattedHTMLMessage id="about.part1" />
        </p>
        <p>
          <FormattedHTMLMessage id="about.part2" />
        </p>
        <p>
          <FormattedHTMLMessage id="about.part3" />
        </p>
        <p>
          <FormattedHTMLMessage id="about.part4" />
        </p>
        {/* <p>
          <FormattedHTMLMessage id="about.part5" />
        </p> */}
      </div>

      <div className="masonry__item cont-order-6 masonry__item--left masonry__item masonry__item--last p-4 border-right">
        <h2>
          <FormattedHTMLMessage id="references.title" />
        </h2>
        <div>
          <h3>
            <FormattedHTMLMessage id="references.eu.title" />
          </h3>
          <p>
            <FormattedHTMLMessage id="references.eu.part1" />
          </p>
          <p>
            <FormattedHTMLMessage id="references.eu.part2" />
          </p>
          <p>
            <FormattedHTMLMessage id="references.eu.part3" />
          </p>
          <p>
            <FormattedHTMLMessage id="references.eu.part4" />
          </p>
        </div>
        <div>
          <h3>
            <FormattedHTMLMessage id="references.presto.title" />
          </h3>
          <p>
            <FormattedHTMLMessage id="references.presto.part1" />
          </p>
        </div>
        <div>
          <h3>
            <FormattedHTMLMessage id="references.ingstav.title" />
          </h3>
          <p>
            <FormattedHTMLMessage id="references.ingstav.part1" />
          </p>
        </div>
        <div>
          <h3>
            <FormattedHTMLMessage id="references.air.title" />
          </h3>
          <p>
            <FormattedHTMLMessage id="references.air.part1" />
          </p>
        </div>
        <div>
          <h3>
            <FormattedHTMLMessage id="references.ci.title" />
          </h3>
          <p>
            <FormattedHTMLMessage id="references.ci.part1" />
          </p>
        </div>
      </div>

      <div></div>

      <div className="masonry__item cont-order-2 masonry__item--right p-4">
        <h3>
          <FormattedHTMLMessage id="price.title" />
        </h3>
        <p>
          <FormattedHTMLMessage id="price.content" />
        </p>
      </div>

      <div className="masonry__item cont-order-3 masonry__item--right p-4">
        <h3>
          <FormattedHTMLMessage id="specialization.title" />
        </h3>
        <ul>
          <li>
            <FormattedHTMLMessage id="specialization.law" />
          </li>
          <li>
            <FormattedHTMLMessage id="specialization.economy" />
          </li>
          <li>
            <FormattedHTMLMessage id="specialization.consumer" />
          </li>
          <li>
            <FormattedHTMLMessage id="specialization.environment" />
          </li>
          <li>
            <FormattedHTMLMessage id="specialization.social" />
          </li>
          <li>
            <FormattedHTMLMessage id="specialization.humanities" />
          </li>
          <li>
            <FormattedHTMLMessage id="specialization.technical" />
          </li>
          <li>
            <FormattedHTMLMessage id="specialization.press" />
          </li>
          <li>
            <FormattedHTMLMessage id="specialization.correspondence" />
          </li>
        </ul>
      </div>

      <div className="masonry__item cont-order-5 masonry__item--right p-4">
        <h3>
          <FormattedHTMLMessage id="education.title" />
        </h3>
        <p>
          <FormattedHTMLMessage id="education.vsb" />
        </p>
        <p>
          <FormattedHTMLMessage id="education.cuni" />
        </p>
        <p>
          <FormattedHTMLMessage id="education.llm" />
        </p>
      </div>

      <div className="masonry__item cont-order-7 masonry__item--right p-4">
        <h3>
          <FormattedHTMLMessage id="contact.title" />
        </h3>
        <ul>
          <li>
            <a href="mailto:martinkova@translation-service.cz">
              <FormattedHTMLMessage id="contact.email" />
            </a>
          </li>
          <li>
            <a href="tel:+4201234567890">
              <FormattedHTMLMessage id="contact.mobile" />
            </a>
          </li>
          <li>
            <a href="tel:+4201234567890">
              <FormattedHTMLMessage id="contact.tel" />
            </a>
          </li>
          <li className="mt-8 mb-8">
            <FormattedHTMLMessage id="contact.address" />
          </li>
          <li>
            <FormattedHTMLMessage id="contact.ic" />
          </li>
          <li>
            <FormattedHTMLMessage id="contact.tradeId" />
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default HomeContent;
