import React from "react"
import { FormattedMessage } from "react-intl"
import SelectLanguage from "./SelectLanguage"
import "./header.css"

const Header = props => (
  <>
    <div className="container mx-auto px-8 flex flex-wrap text">
      <SelectLanguage langs={props.langs} />
    </div>
    <h1 className="header__heading">
      <FormattedMessage id="header.name" />
    </h1>
    <h1 className="header__tagline">
      <FormattedMessage id="header.tagline" />
    </h1>
  </>
)

export default Header
