import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"
import { FormattedMessage } from "react-intl"
import "./lang.css"

const SelectLanguage = props => {
  const links = props.langs.map(lang => (
    <Link to={lang.link} key={lang.langKey} className={lang.selected ? 'lang-selected' : ''}>
      <li>{lang.langKey}</li>
    </Link>
  ))

  return (
    <section className="lang-container">
      <header>
        <FormattedMessage id="selectLanguage" />
      </header>
      <ul className="lang-ul">{links}</ul>
    </section>
  )
}

SelectLanguage.propTypes = {
  langs: PropTypes.array,
}

export default SelectLanguage
