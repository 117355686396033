module.exports = {
  'selectLanguage': 'Select language',
  'offering.heading': 'What can I&nbsp;offer?',
  'header.name': 'Monika Martinková',
  'header.tagline': 'Certified translator of the Czech and German and English languages',
  'home.intro1': 'I can offer my long-term experiences with translations of dozens of thousand pages. According to the last certificate issued by the European Commission, I&nbsp;have translated about <strong> 58&nbsp;000 pages</strong> only for this institution; it corresponds to approximately <strong>260 books</strong>. I&nbsp;have translated thousands of pages for other institutions or businesses in private as well as&nbsp;public sector.',
  'home.intro2': 'Compared to the translation agencies, I&nbsp;am able to compete on price, while providing the entire translation process, including proofreading and stylistic adjustments. This ensures <strong> consistency </strong> of the whole text and <strong>careful use of the terms</strong>.  I&nbsp;always deliver translations within the agreed deadline.',
  'offering.content': 'I offer writing translations (not interpreting), namely from <strong>English</strong>, <strong>German</strong>, <strong>Polish</strong> and <strong>Slovak</strong> into <strong>Czech</strong> and from <strong>Czech</strong> into <strong>English</strong> and <strong>German</strong>.',
  'offering.legal': 'I provide certified translations. I&nbsp;provide also proofreading, text revisions, and stylistic adjustments.',
  'offering.tools': 'I use computer-aided translation (CAT) tools - currently <strong>SDL Trados Studio 2019</strong>.',
  'about.title': 'About me',
  'about.part1': 'I began with translations at the beginning of the 1990s in a&nbsp;company where I&nbsp;started working after graduating. At that time, business relations with foreign suppliers began to develop and the first contracts financed from the EU funds appeared. Because I&nbsp;had passed the language exams, I&nbsp;was in charge of translating and interpreting during various meetings. I&nbsp;continued in translating documents for my employer during my maternity leave and gradually I&nbsp;began to provide services to other customers as well.',
  'about.part2': 'Because of strong interest in translations, I&nbsp;decided to work as a&nbsp;free-lance translator after my maternity leave and since 1995 I&nbsp;possess a&nbsp;trade licence.',
  'about.part3': 'In the late 1990s, I&nbsp;was approached by a&nbsp;Czech agency that won a&nbsp;tender for translations of the EU <em>acquis</em> before the Czech Republic\'s accession to the European Union in order to participate in the preparation of the Czech translations. I&nbsp;then worked for this agency until 2003. In this year, the first tenders concerning translations for the EU institutions were announced, in which I&nbsp;decided to take part in view of my experience. Since then I&nbsp;have been working almost exclusively for the EU institutions, especially for the European Commission.',
  'about.part4': 'In 2018, the OECD (Organization for Economic Co-operation and Development) approached me with an offer for cooperation, with which I&nbsp;then concluded a&nbsp;contract on the provision of translation services.',
  'about.part5': 'And I&nbsp;recently have signed a&nbsp;contract regarding the translation of my first book with the Jan Melvil Publishing; this work requires a&nbsp;somewhat different approach and skills, therefore I&nbsp;have been looking forward to this.',
  'references.title': 'References',
  'references.eu.title': 'Directorate-General  for Translation of the European Commission',
  'references.eu.part1': 'This translation has been evaluated as \'very good\'. Thank you for your high-quality work.',
  'references.eu.part2': 'Thank you very much for your translation, we were very happy with it. We particularly appreciate your consistent quoting the relevant Court judgments, Regulations etc. and your faculty of interpretation.',
  'references.eu.part3': 'We found (your translation) very meticulous, and we have appreciated its stylistic level, the precision, as well as the correctness of quotations from the Court decisions and guidelines cited in the text.',
  'references.eu.part4': 'Certificate of the number of pages translated for the European Commission – about <strong>58&nbsp;000&nbsp;pages</strong>, <strong>98&nbsp;%</strong> translations &nbsp;evaluated as <strong>very good&nbsp;/&nbsp;good</strong>',
  'references.presto.title': 'Presto Translation Centre, Prague',
  'references.presto.part1': 'Presto uses the translation skills of Mrs Monika Martinková for many years, and in the past two years she has been one of the translators who have been involved in the translation of European Community law for the Office of the Government of the Czech Republic in our company. Its translations from and into English are at a&nbsp;very high level. (&hellip;)',
  'references.ingstav.title': 'Ingstav Ostrava, a.s.',
  'references.ingstav.part1': 'The translations were always carried out in very high quality and fast.',
  'references.air.title': 'AIR-nemovitosti, Prague',
  'references.air.part1': 'Our company has been working with Mrs Martinkova very successfully  for several years. Our customers, e.g. HVB, ErsteBank or Deutsche bank, are satisfied with the translations. As concerns the deadlines, always at the agreed time.',
  'references.ci.title': 'Czech International, a.s., Havířov',
  'references.ci.part1': 'Mrs. Martinková always works very flexibly and with very precise terminology. Therefore, we can recommend her professional services.',
  'price.title': 'What is the price?',
  'price.content': 'The price always depends on the target language, the difficulty of the text, the required date of completion and the scope (in the case of larger texts or long-term cooperation, possibility of discounts).',
  'time.title': 'When the translation will be done?',
  'time.content': 'As regards the deadline, I&nbsp;always try to meet the requirements of the customer, taking into account the scope of the translation and my capacity. I&nbsp;<strong>always strictly</strong> adhere to the agreed term.',
  'specialization.title': 'What is my specialization?',
  'specialization.law': 'Law (especially commercial law and law of the EU)',
  'specialization.economy': 'All branches of economics',
  'specialization.consumer': 'Consumer matters',
  'specialization.environment': 'Environment',
  'specialization.social': 'Social area',
  'specialization.humanities': 'Humanities and social sciences',
  'specialization.technical': 'Technical texts',
  'specialization.press': 'Press articles',
  'specialization.correspondence': 'Correspondence',
  'education.title': 'My education',
  'education.vsb': '<strong>Degree in economics</strong> - Vysoká škola báňská - Technická univerzita v&nbsp;Ostravě (VSB Technical University of Ostrava)',
  'education.cuni': '<strong>The two-term JURIDIKUM Legal English course for interpreters and translators</strong> - Law Faculty of Charles University, Prague ',
  'education.llm': '<strong>Executive Master of Laws \u0013 Commercial Law</strong> \u0013 Commercial law in the context of the Union law',
  'contact.title': 'Contact',
  'contact.email': 'martinkova@translation-service.cz',
  'contact.mobile': 'Mobile phone: +420&nbsp;777&nbsp;157&nbsp;503',
  'contact.tel': 'Telephone number: +420&nbsp;596&nbsp;410&nbsp;709',
  'contact.address': 'Monika Martinková<br />Vrchlického 6/1484<br />Havířov PSČ 736 01',
  'contact.ic': 'ID: 60959495',
  'contact.tradeId': 'Trade Licence No: 99/637556/3169/Kr'
}
